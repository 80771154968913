<template>
	<div
	>
		<div>
			<h5 class="color-primary">이용약관</h5>
			<div
				class="box pa-20 mt-10 min-height-150 overflow-y-auto"
				:inner-html.prop="shop_info.site_terms | nl2br"
			></div>
			<div class="mt-10"><label> <input v-model="terms" type="checkbox" /> 이용약관에 동의 합니다</label></div>
		</div>
		<div class="mt-30">
			<h5 class="color-primary">개인정보처리방침</h5>
			<div
				class="box pa-20 mt-10 min-height-150 overflow-y-auto"
				:inner-html.prop="shop_info.site_personal | nl2br"
			></div>
			<div class="mt-10"><label> <input v-model="personal" type="checkbox" /> 이용약관에 동의 합니다</label></div>
		</div>

		<div class="mt-50 text-center">
			<button
				class="btn btn-fill"
				@click="onConfirm"
				:disabled="is_disabled"
			>본인 인증</button>
		</div>

		<div
			v-if="is_on_confirm"
			class="position-fixed-full"
		>

			<div
				style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
			></div>
			<div
				style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
				class="color-white text-center size-px-24"
			>

				<iframe
					v-show="true"
					:src="nice_url"
					class="full-height full-width"
				></iframe>
			</div>
		</div>
	</div>
</template>


<script>

export default{
	name: 'AuthAgree'
	, components: {}
	, props: ['codes', 'shop_info']
	, data: function(){
		return {
			program: {
				name: '본인 인증 및 약관 동의'
				, code: 'join'
				, title: true
				, bottom: true
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
				, top_line: true
			}
			, terms: false
			, personal: false
			, is_on_confirm: false
			, nice_url: ''
		}
	}
	,computed: {
		is_disabled: function(){
			let t = true
			if(this.terms && this.personal){
				t = false
			}
			return t
		}
	}
	,methods: {
		onConfirm: async function(){
			// this.is_on_confirm = !this.is_on_confirm
			window.open(this.nice_url, 'nice', 'width=320px')
		}
		, cancel: function(){
			window.location.reload()
		}
	}
	, destroyed() {

	}

	,created: function(){
		this.$bus.$emit('onLoad', this.program)

		let baseUrl = this.$baseUrl

		this.nice_url = baseUrl + '/nice/request'

	}
}
</script>

<style>

</style>