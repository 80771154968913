<template>
	<AuthAgree
		v-if="step == 1"

		:shop_info="shop_info"
		@click="next"
	></AuthAgree>

	<JoinForm
		v-else-if="step == 2"
		:user="item"
		@click="setItem"
	></JoinForm>

	<JoinResult
		v-else-if="step == 4"
		:user="item"
	></JoinResult>

	<JoinFalse
		v-else-if="step == 3"
		:message="message"
	></JoinFalse>
</template>


<script>

import AuthAgree from "./Agree";
import JoinForm from "./JoinForm";
import JoinFalse from "@/view/Auth/JoinFalse";
import JoinResult from "@/view/Auth/Result";

export default{
	name: 'Join'
	,props: ['codes', 'shop_info']
	, components: {JoinResult, JoinFalse, JoinForm, AuthAgree, }
	,data: function(){
		return {
			program: {
				name: '회원가입'
				, code: 'join'
				, title: true
				, bottom: true
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
				, top_line: true
			}
			, step: 1
			, item: {
				name: ''
				, phone: ''
				, birthdate: ''
			}
			, message: ''
		}
	}
	,computed: {

	}
	,methods: {
		next: function(){
			this.step = 2
			this.$bus.$emit('on', false)
		}
		, niceResult: function(e){
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					if(data && typeof data == 'string'){
						data = JSON.parse(data)
						if(data.type == 'nice_result'){
							if(data.result){
								this.item.name = data.data.name
								this.item.phone = data.data.phone
								this.item.birthdate = data.data.birthdate
								this.step = 2
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.step = 3
				this.message = e
			}
		}
		, setItem: function(item){
			this.item = item
			this.step = 4
		}
	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)

		window.removeEventListener('message', this.niceResult)
		setTimeout( () => {
			window.addEventListener('message', this.niceResult)
		}, 100)
	}
}
</script>

<style>

</style>