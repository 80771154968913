<template>
	<div
		class="flex-column justify-center "
	>
		<div
			class="bg-login-content box bottom"
		>
			<div
				class=" text-center"
			>
				<div
					class="bg-primary size-px-16 color-white font-weight-bold pa-10 "
				><img :src="require(`@/assets/images${$skin.img}logo_bottom.png`)" class="logo"/></div>
			</div>

			<div class="pa-50-20 text-center">

				<div><h3>{{ message ? message : '이미 가입된 정보이거나 잘못된 요청입니다.'}}</h3> </div>

				<div class="mt-50 justify-space-around ">
					<button
						class="btn-fill btn-round"
						@click="toLogin"
					>로그인 바로가기</button>
					<button
						v-if="false"
						class="btn-outline btn-round btn-ss"
						@click="toId">아이디 찾기</button>
					<button
						v-if="false"
						class="btn-outline btn-round btn-ss"
						@click="toPw">비밀번호 찾기</button>
				</div>
			</div>


		</div>
	</div>
</template>

<script>

export default{
	name: 'JoinFalse'
	,props: ['message']
	,data: function() {
		return {

		}
	}
	,methods: {
		toLogin: function(){
			this.$bus.$emit('to', { name: 'Login'})
		}
		, toId: function(){
			this.$bus.$emit('to', { name: 'FindId'})
		}
		, toPw: function(){
			this.$bus.$emit('to', { name: 'FindPw'})
		}
	}
	,created: function(){
	}
}
</script>

<style>
.bg-login { background-color: #eee; padding: 10px;}

@media (min-width: 720px) {
	.bg-login-content { background-color: white; border-radius: 5px; min-width: 320px; max-width: 480px; margin: 0 auto}
}

.auto-login { vertical-align: middle; font-size: 14px; }

.login-menu { }
.login-menu a { font-size: 14px; font-weight: 400}

.toggle-password { position: relative; }
.toggle-password .toggle { position: absolute; right: 10px; top: 20px; color: #bbb}
.toggle-password .toggle.on { color: #0f6ecd}

.error-underline { background: none; border-bottom: 1px solid red !important;}

</style>