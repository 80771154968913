<template>
	<div
		class=" join "
	>
		<div
			class="under-line-primary"
		>
		<div
			class="width-480-pc ma-auto flex-div  pb-20"
		>
			<div
				v-if="false"
				class="mt-10"
			>
				<h6>대리점 코드</h6>
				<div>
					<input
						v-model="item.admin_code"
						type="text"
						class="mt-10 pa-10 input-underline"
						placeholder="대리점 코드를 입력하세요"
						:disabled="isGetAdminCode"
					/>
				</div>
			</div>

			<div
				class=" mt-10"
			>
				<h6>이름</h6>
				<div class="pa-10 input-underline bg-gray-light mt-10-m">
					{{ user.name }}
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>휴대폰 번호</h6>
				<div class="pa-10 input-underline bg-gray-light mt-10-m">
					{{ user.phone }}
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>생년월일</h6>
				<div class="pa-10 input-underline bg-gray-light mt-10-m">
					{{ user.birthdate }}
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>아이디</h6>
				<div>
					<input
						v-model="item.member_id"
						type="text" placeholder="아이디를 입력하세요"
						class="pa-10 input-underline"
						:disabled="isCheckId"
						:rules="[$rules.id(item, 'member_id', { min: 6, max: 25})]"
					/>
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>비밀번호</h6>
				<div>
					<input
						v-model="item.member_password"
						type="password" placeholder="영문/숫자/특수문자 포함 8~20자리"
						class="pa-10 input-underline"
						maxlength="50"
					/>
					<div
						v-if="this.txtValidCheckPass != ''"

						class="mt-10 text-right color-red"
					>{{ this.txtValidCheckPass }}</div>
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>비밀번호 확인</h6>
				<div>
					<input
						v-model="item.member_password_confirm"
						type="password" placeholder="비밀번호를 한번 더 입력하세요"
						class="pa-10 input-underline"
						maxlength="50"
					/>
					<div
						v-if="item.member_password_confirm && item.member_password && (item.member_password_confirm != item.member_password)"

						class="mt-10 text-right color-red"
					>비밀번호를 확인하세요</div>
				</div>
			</div>
			<div
				class="mt-10"
				v-if="item.is_on_sms_confirm"
			>
				<h6>SMS 인증</h6>
				<div>
					<div
						v-if="!item.is_sms_confirm"
						class="flex-row justify-space-between"
					>
						<div class="flex-2">
							<input
								v-model="item.sms_code"
								type="number" placeholder="인증코드를 입력하세요"
								class="input-underline"
								:rules="[$rules.max(item, 'sms_code', 6)]"
							/>
						</div>
						<div class="flex-1">
							<button
								@click="postSmsConfirm"
								class="btn btn-primary"
							>인증확인</button>
						</div>
					</div>
					<div
						v-else
						class="input-underline color-success"
					>
						인증이 완료되었습니다.
					</div>
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>이메일</h6>
				<div>
					<input
						v-model="item.member_email"
						type="text" placeholder="이메일을 입력하세요"
						class="input-underline"
						maxlength="50"
					/>
					<div
						v-if="this.txtValidCheckEmail != ''"

						class="mt-10 text-right color-red"
					>{{ this.txtValidCheckEmail }}</div>
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>주소</h6>
				<div>
					<div
						class="mt-10 flex-row"
					>
						<input
							v-model="item.member_post"
							type="number" placeholder="우편번호"
							class="input-box-inline mr-10"
							readonly

							:rules="[$rules.numeric(item, 'member_post', 10)]"
						/>
						<button
							class="btn-default "
							@click="daumPost('default')"
						>주소 검색</button>
					</div>
					<div
						class="mt-10"
					>
						<input
							v-model="item.member_addr1"
							type="text" placeholder="기본 주소"
							class="input-box-inline width-80"
							readonly
							maxlength="80"
						/>
					</div>
					<div
						class="mt-10"
					>
						<input
							v-model="item.member_addr2"
							type="text" placeholder="상세 주소를 입력하세요"
							class="input-box-inline width-80"
							maxlength="50"
						/>

					</div>
				</div>
			</div>

		</div>
		</div>
		<div
			class="mt-30 text-center justify-space-between-m"
		>
			<button
				:disabled="!isNext"
				class="btn btn-fill mr-10 btn-s-m flex-1"
				@click="next"
			>회원가입</button>

			<button
				class="btn btn-outline btn-s-m flex-1"
				@click="next"
			>취소</button>
		</div>

		<DaumPost
			:overlay="daumPostUp"
			@callBack="addPost"

		></DaumPost>

	</div>
</template>


<script>

import DaumPost from '@/components/Daum/DaumPost'

export default{
	name: 'JoinForm'
	,props: ['codes', 'user']
	, components: { DaumPost }
	,data: function(){
		return {
			program: {
				name: '가입 정보 입력'
				, code: 'join'
				, title: true
				, bottom: true
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
				, top_line: true
			}
			,search: {
				COMPFRNAME: ''
			}
			,daumPostUp: false
			,txtValidCheckPass: ''
			,txtValidCheckEmail: ''
			,txtValidCheckCompany: ''
			,item: {
				member_id: ''
				, member_email: ''
				, admin_code: this.$route.params.code
				, is_agree: false
				, sms_confirm: false
				, member_tell: ''
				, sms_code: ''
				, is_use_sms_auth: true
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, member_name: ''
				, birthdate: ''
			}
			, is_check_id: false
		}
	}
	,computed: {
		isNext: function(){
			const self = this
			const required = ['member_id', 'member_password', 'member_password_confirm', 'member_name', 'member_post', 'member_addr1', 'member_addr2', 'member_tell', 'member_email', 'is_agree']
			let isNext = false
			for(let i = 0; i < required.length; i++){
				if(!self.item[required[i]]){
					isNext = true
					break
				}
			}

			// 비밀번호 유효성 검사
			let isPass = this.$rules.password(self.item, 'member_password')
			if(isPass === true){
				self.txtValidCheckPass = ''
			}else{
				self.txtValidCheckPass = isPass
				isNext = true
			}

			// 비밀번호 체크
			if(self.item['member_password_confirm'] != self.item['member_password']){
				isNext = true
			}

			// 이메일 유효성 검사
			if(this.$rules.email(self.item, 'member_email')){
				self.txtValidCheckEmail = ''
			}else{
				if(self.item.member_email.length == 0){
					self.txtValidCheckEmail = ''
				}else{
					self.txtValidCheckEmail = '잘못된 형식의 이메일 주소입니다.'
				}

				isNext = true
			}

			return isNext
		}
		,isGetAdminCode: function(){
			if(this.$route.params.code){
				return true
			}else{
				return false
			}
		}
		,isCheckId: function(){
			if(this.is_check_id){
				return true
			}else{
				return false
			}
		}
		, is_sms_auth: function(){
			let t = true
			if(!this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = false
			}
			return t
		}
		, is_member_tell: function(){
			let t = false
			if(this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = true
			}
			return t
		}
	}
	,methods: {
		daumPost: function (type) {
			this.$emit('setOverlay')
			this.daumPostUp = true
			this.daumPostType = type
		}
		, addPost: function (call) {

			this.$set(this.item, 'member_post', call.zonecode)
			this.$set(this.item, 'member_addr1', call.address)

			this.daumPostUp = false
			this.$emit('setOverlay')
		}
		,next: async function(){

			try{

				this.item.member_name = this.user.name
				this.item.member_tell = this.user.phone
				this.item.birthdate = this.user.birthdate
				const result = await this.$request.init({
					method: 'post'
					,url: '/auth/postJoin'
					,data: this.item
				})
				if(result.success){
					this.$emit('click', this.item)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		,toJoinResult: function(){
			this.$router.push({ name: 'JoinResult'})
		}
		,toBack: function(){
			this.$router.back()
		}
		, resetSmsAuth: function(){
			this.item.is_on_sms_confirm = false
			this.item.is_sms_confirm = false
			this.item.sms_code = ''
		}
		, postSmsAuth: async function(){
			try{
				this.item.is_on_sms_confirm = false
				const result = await this.$request.init({
					method: 'post'
					,url: '/auth/postSmsAuth'
					,data: {
						member_tell: this.item.member_tell
						, admin_code: this.item.admin_code
					}
				})
				if(result.success){
					this.item.is_on_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postSmsConfirm: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: '/auth/postSmsConfirm'
					,data: {
						member_tell: this.item.member_tell
						, sms_code: this.item.sms_code
					}
				})
				if(result.success){
					this.item.is_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>

<style>

	@media (min-width: 720px) {

		.flex-div > div {
			display: flex; justify-content: space-between;
			gap: 20px;
		}
		.flex-div > div > h6 {
			width: 180px;
		}
		.flex-div > div > div {
			text-align: left;
			width: 100%;
		}
		.join h6 { margin-top: 10px !important;}
	}
</style>